* {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}
